import type { LocaleObject } from '@nuxtjs/i18n'
import { useI18n } from 'vue-i18n'

export function useAppLocale() {
  const applyLocale = () => {
    const { isCurrentLangActive, primaryLocale, langCode } = storeToRefs(useStoreStore())
    const { setLocale, locales, fallbackLocale } = useI18n()

    let lang
    if (useEmbedded().isEmbedded) {
      lang = isCurrentLangActive.value ? langCode.value : primaryLocale.value
    }

    lang = lang ?? navigator.language

    const [appLang] = lang.split('-')
    const supportedLocales = locales.value.map((locale: LocaleObject) => locale.code)

    if (supportedLocales.includes(appLang)) {
      setLocale(appLang)
    } else {
      setLocale(String(fallbackLocale.value))
    }
  }

  return { applyLocale }
}
